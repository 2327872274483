export default function Footer({ ws }) {

    const container = {
      //height: "25vw",
      width: "90vw",
      maxWidth: 1200,
      backgroundColor: "#99d8e0",
      display: "grid",
      gridTemplateColumns:
          ws.innerWidth > 800
            ? "repeat(3, 1fr)"
            : "repeat(1, 1fr)",
      flexDirection: 
          ws.innerWidth < 800
          ? "column"
          : "row",
      marginTop: "50px",
      marginBottom: "100px"
    }

    const frame = {
      //width: "33.33%",
      //minWidth: "100px",
      height: "100%",
      //backgroundColor: "yellow",
    }

    const caption = {
      color: "#9C1006",
    }

    const detail = {
      fontSize: "16px",
      //textAlign: "left",
      //marginLeft: "50px",
    }

    const dayNamesCaption = {
      fontWeight: "700",
    }

    const designerCaption = {
      fontSize: "26px",
    }

    const copyrightLine = {
      fontSize: "14px",
      marginTop: "20px",
    }





    

    return (
      <div style={container}>
        <div style={frame}>
          <p style={caption}>
            Contact
          </p>
          <div style={detail}>
            <p>G8, Ground Floor, Sunset Mall, Jumeirah 3, Dubai, UAE</p>
            <p>+971 50 122 0535</p>
            <p>+971 4 259 3395</p>
            <p>rjigarchi@gmail.com</p>
          </div>
        </div>

        <div style={frame}>
          <p style={caption}>
            Opening
          </p>
          <div style={detail}>
            <p style={dayNamesCaption}>
              All Week Days
            </p>
            <p>11:00 AM - 02:00 PM</p>
            {/* <p style={dayNamesCaption}>
              Thursday - Sunday
            </p>
            <p>09AM - 02AM</p> */}
          </div>
        </div>

        <div style={frame}>
          <p style={caption}>
            Designed By
          </p>

          <div style={detail}>
            <p style={designerCaption}>
              paranoid.ir
            </p>
            {/* <a style={{color: "white"}} href="https://paranoid.ir">
              Click to Order A New App
              <br/>
              look like Jigarchi !
            </a> */}
          </div>

          <div style={copyrightLine}>
            <a>©</a>
            <a style={{color: "white"}} href="https://jigarchi.ae">
              Jigarchi
            </a>
            <a>, All Right Reserved.</a>
          </div>
        </div>


        
        </div>
    )
}



