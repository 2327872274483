const menu = {
        jigarList:
        [
            {
                name: "Liver - جیگر",
                desc: "Charbroiled Lamb Liver Pieces (1 skewer)",
                moreDesc: "",//"Pieces of charbroiled lamb liver and rump with bread, chillis, lemons and onions",
                pic: require("../images/liver.png"),
                radius: 50,
                price: "12 dhs",
            },
            {
                name: "Heart - دل",
                desc: "Charbroiled Lamb Heart (1 skewer)",
                pic: require("../images/hearts.png"),
                radius: 50,
                price: "15 dhs"
            },
            {
                name: "Kidney - قلوه",
                desc: "Charbroiled Lamb Kidney (1 skewer)",
                pic: require("../images/kidney.png"),
                radius: 50,
                price: "15 dhs",
            },
            {
                name: "Rump - دنبه",
                desc: "Charbroiled Lamb Rump (1 skewer)",
                pic: require("../images/rump.png"),
                radius: 50,
                price: "15 dhs"
            },
            {
                name: "Eggs - دنبلان",
                desc: "Charbroiled Lamb Eggs (1 skewer)",
                pic: require("../images/eggs.png"),
                radius: 50,
                price: "15 dhs",
            },
            {
                name: "SweetBread - خوشگوشت",
                desc: "Charbroiled Lamb SweetBread (1 skewer)",
                pic: require("../images/sweet.png"),
                radius: 50,
                price: "15 dhs"
            },
            {
                name: "Thymus - خوئک",
                desc: "Charbroiled Lamb Thymus (1 skewer)",
                pic: require("../images/thymus.png"),
                radius: 50,
                price: "15 dhs",
            },
            {
                name: "Mutton Liver - جیگرپیچ",
                desc: "Charbroiled Lamb Mutton Liver (1 skewer)",
                pic: require("../images/mutton.png"),
                radius: 50,
                price: "20 dhs"
            },
            {
                name: "Rump Liver - جیگردنبه",
                desc: "Charbroiled Lamb Rump Liver (1 skewer)",
                pic: require("../images/rump-liver.png"),
                radius: 50,
                price: "25 dhs"
            },
            {
                name: "Kebab Koobideh - کباب کوبیده",
                desc: "Charbroiled Kebab Koobideh (1 skewer)",
                pic: require("../images/koob.png"),
                radius: 50,
                price: "30 dhs",
            },
            {
                name: "Hunting Heart - دل شکاری",
                desc: "Charbroiled Lamb Hunting Heart (1 skewer)",
                pic: require("../images/hheart.png"),
                radius: 50,
                price: "45 dhs",
            },
            {
                name: "Mushrooms - قارچ کبابی",
                desc: "Charbroiled Mushrooms (1 skewer)",
                pic: require("../images/mushrooms.png"),
                radius: 50,
                price: "20 dhs"
            },
            {
                
                name: "Special Heart and Liver Food",
                desc: "Mix of Lamb Heart and Liver Food",
                pic: require("../images/mix.png"),
                //width: 140,
                radius: 50,
                //marginLeft: 10,
                price: "55 dhs",
            },
        ],
        kebabList: [
            {
                number: "I",
                name: "Kebab Roulette",
                desc: "Charbroiled roulette kebabs",
                pic: require("../images/Roulette.png"),
                radius: 0,
                marginLeft: 0,
                price: "65 dhs",
            },
            {
                number: "II",
                name: "Kebab Goosht o Donbeh (Meat and Rump)",
                desc: "Charbroiled Meat and Rump kebabs",
                pic: require("../images/GooshtDonbeh.png"),
                radius: 0,
                marginLeft: 0,
                price: "50 dhs",
            },
            {
                number: "III",
                name: "Chicken Wings Kebab (6 pieces)",
                desc: "Charbroiled Chicken wings",
                pic: require("../images/Wing.png"),
                radius: 0,
                marginLeft: 0,
                price: "40 dhs",
            },
            {
                number: "IV",
                name: "Chicken Wings Kebab Spicy (6 pieces)",
                desc: "Charbroiled Spicy Chicken wings",
                pic: require("../images/WingSpicy.png"),
                width: 140,
                radius: 0,
                marginLeft: 10,
                price: "40 dhs",
            },
            {
                number: 1,
                name: "Kebab Koobideh with Rice (2 skewers)",
                desc: "2 Charbroiled seasoned minced kebabs + cooked rice",
                pic: require("../images/KoobidehRice2.png"),
                radius: 0,
                marginLeft: 0,
                price: "55 dhs",
            },
            {
                number: 2,
                name: "Kebab Barg",
                desc: "Finest cut of beef fillet charbroiled open flame",
                pic: require("../images/Barg.png"),
                radius: 0,
                marginLeft: 0,
                price: "60 dhs",
            },
            {
                number: 3,
                name: "Kebab Sultani",
                desc: "Combination platter of one skewer each of beef kebab barg and Lamb kebab koobideh",
                pic: require("../images/Sultani.png"),
                radius: 0,
                marginLeft: 0,
                price: "75 dhs",
            },
            {
                number: 4,
                name: "Kebab Chenjeh The Calf Beef",
                desc: "Grilled tender the calf beef tikka fillet kebab",
                pic: require("../images/Chenjeh.png"),
                radius: 0,
                marginLeft: 0,
                price: "60 dhs",
            },
            {
                number: 5,
                name: "Kebab Chenjeh Masti",
                desc: "Grilled tender the calf beef tikka fillet kebab(yogurt marinate then charbroiled)",
                pic: require("../images/ChenjehMasti.png"),
                radius: 0,
                marginLeft: 0,
                price: "60 dhs",
            },
            {
                number: 6,
                name: "Kebab Shishlick Lamb",
                desc: "5 pieces baby spring natural lamb chops",
                pic: require("../images/Shishlick.png"),
                radius: 0,
                marginLeft: 0,
                price: "105 dhs",
            },
            {
                number: 7,
                name: "Kebab Bakhtiari",
                desc: "Charcoaled mixed lamb and chicken kebab",
                pic: require("../images/Bakhtiari.png"),
                radius: 0,
                marginLeft: 0,
                price: "60 dhs",
            },
            {
                number: 8,
                name: "Kebab Vaziri",
                desc: "Combination platter of one skewer each of chicken kebab and Lamb kebab koobideh",
                pic: require("../images/Vaziri.png"),
                radius: 0,
                marginLeft: 0,
                price: "65 dhs",
            },
            {
                number: 9,
                name: "Kebab Loghmeh Lamb",
                desc: "Charcoaled seasoned minced lamb kebab",
                pic: require("../images/KoobidehRice.png"),
                radius: 0,
                marginLeft: 0,
                price: "70 dhs",
            },
            {
                number: 10,
                name: "Kebab Loghmeh Negindar Lamb",
                desc: "Charcoaled seasoned minced lamb kebab with chicken",
                pic: require("../images/Negin.png"),
                radius: 0,
                marginLeft: 0,
                price: "80 dhs",
            },
            {
                number: 11,
                name: "Chicken Kebab Special Saffron",
                desc: "Young chicken breast (special saffron marinate then charbroiled)",
                pic: require("../images/SpecialSaf.png"),
                radius: 0,
                marginLeft: 0,
                price: "55 dhs",
            },
            /* {
                number: 12,
                name: "Chicken Kebab Special Saffron (Thigh)",
                desc: "Young chicken thigh (special saffron marinate then charbroiled)",
                pic: require("../images/ChickenThigh.png"),
                radius: 0,
                marginLeft: 0,
                price: "60 dhs",
            }, */
            {
                number: 12,
                name: "Chicken Kebab Masti (Laari)",
                desc: "Young chicken breast (yogurt saffron marinate then charbroiled)",
                pic: require("../images/ChickenMasti.png"),
                radius: 0,
                marginLeft: 0,
                price: "55 dhs",
            },
            {
                number: 13,
                name: "Chicken Kebab Tandoori (Spicy)",
                desc: "Skinless young chicken breast (spicy marinate)",
                pic: require("../images/Tandoori.png"),
                radius: 0,
                marginLeft: 0,
                price: "55 dhs",
            },
            {
                number: 14,
                name: "Special Dish (4 Skewers)",
                desc: "2 Skewers Kebab Koobideh + 2 Skewers Chicken Kebab + 2 Cooked Rice Dish",
                pic: require("../images/Special4.png"),
                radius: 0,
                marginLeft: 0,
                price: "150 dhs",
            },{
                number: 15,
                name: "Special Dish (6 Skewers)",
                desc: "3 Skewers Kebab Koobideh + 2 Skewers Chicken Kebab + 1 Skewer Kebab Chenjeh + 3 Cooked Rice Dish",
                pic: require("../images/Special6.png"),
                radius: 0,
                marginLeft: 0,
                price: "185 dhs",
            },
            {
                number: 16,
                name: "Special Dish (8 Skewers)",
                desc: "4 Skewers Kebab Koobideh + 3 Skewers Chicken Kebab + 1 Skewer Kebab Chenjeh + 3 Cooked Rice Dish",
                pic: require("../images/Special8.png"),
                radius: 0,
                marginLeft: 0,
                price: "250 dhs",
            },
            {
                number: 17,
                name: "Chelo Kareh ( Rice + Butter )",
                desc: "Hot Cooked Rice + Butter",
                pic: require("../images/CheloKareh.png"),
                radius: 0,
                marginLeft: 0,
                price: "15 dhs",
            },


        ],
        qqList: [
            {
                number: 1,
                name: "Qormeh Sabzi",
                desc: "Herbs, kidney beans, dried lime, lamb; served with rice",
                pic: require("../images/Qormeh.jpg"),
                radius: 0,
                marginLeft: 0,
                price: "55 dhs",
            },
            {
                number: 1,
                name: "Khoresht Qeymeh",
                desc: "Diced mutton, tomatoes, split peas, onion, and dried lime, garnished with crispy potatoes; served with rice",
                pic: require("../images/Qeymeh.jpg"),
                radius: 0,
                marginLeft: 0,
                price: "55 dhs",
            },

        ],
        appetizerList: [
            {
                number: 1,
                name: "Salad Shirazi",
                desc: "Diced Tomato, Cucumber, Onion + Spices",
                pic: require("../images/Shirazi.jpg"),
                radius: 0,
                marginLeft: 0,
                price: "15 dhs",
            },
            /* {
                number: 2,
                name: "Salad Season",
                desc: "Lettuce, Tomato, Cucumber + Special Sauce",
                pic: require("../images/Salad.jpg"),
                radius: 0,
                marginLeft: 0,
                price: "15 dhs",
            }, */
            {
                number:2,
                name: "Mast o Khiar",
                desc: "Walnut, Yogurt, Cucumber, Raisins",
                pic: require("../images/MastKh.jpg"),
                radius: 0,
                price: "10 dhs",
            },
            {
                number:3,
                name: "Mast Moosir (Shallot Yogurt)",
                desc: "Shallot, Yogurt",
                pic: require("../images/MastMS.jpg"),
                radius: 0,
                price: "10 dhs",
            },
            {
                number:4,
                name: "Mast (Yogurt)",
                desc: "Strained Yogurt",
                pic: require("../images/Mast.jpg"),
                radius: 0,
                price: "5 dhs",
            },
            {
                number:5,
                name: "Zeytoon Parvardeh (Marinated Olive)",
                desc: "Enriched olive with walnut and garlic",
                pic: require("../images/OliveZP.jpg"),
                radius: 0,
                price: "25 dhs",
            },
            {
                number:6,
                name: "Pickled Garlic",
                desc: "Pickled Garlic",
                pic: require("../images/GarlicP.jpg"),
                radius: 0,
                price: "15 dhs",
            },
            {
                number:7,
                name: "Pickled Cherries",
                desc: "Pickled Cherries (Albaloo Torshi)",
                pic: require("../images/jigarchi-log.png"),
                radius: 0,
                price: "20 dhs",
            }
        ],
        dooghList:
        [
            {
                number: 1,
                name: "Abali Doogh",
                desc: "LABAN Abali",
                pic: require("../images/abali.jpg"),
                radius: 50,
                price: "10 dhs",
            },
            {
                number: 2,
                name: "Kaleh Doogh",
                desc: "LABAN Seven Kalleh",
                pic: require("../images/kalleh.jpg"),
                radius: 50,
                price: "10 dhs"
            },
        ],
        softDrinkList:
        [
            {
                number: 3,
                name: "Coca-Cola",
                desc: "Coca-Cola Original taste",
                pic: require("../images/coca-cola-org.jpg"),
                radius: 50,
                price: "8 dhs",
            },
            {
                number: 4,
                name: "Coca-Cola ZERO",
                desc: "Coca-Cola ZERO CALORIES",
                pic: require("../images/CocaCola.jpg"),
                radius: 50,
                price: "8 dhs"
            },
            {
                number: 5,
                name: "Coca Cola Light",
                desc: "Coca Cola Light",
                pic: require("../images/coca-cola-light.jpg"),
                radius: 50,
                price: "8 dhs"
            },
            {
                number: 6,
                name: "Sprite",
                desc: "Sprite",
                pic: require("../images/sprite.jpg"),
                radius: 50,
                price: "8 dhs"
            },
            {
                number: 7,
                name: "Sprite Zero",
                desc: "Sprite Zero",
                pic: require("../images/sprite-zero.jpg"),
                radius: 50,
                price: "8 dhs"
            },
            {
                number: 8,
                name: "Fanta",
                desc: "Fanta",
                pic: require("../images/fanta.jpg"),
                radius: 50,
                price: "8 dhs"
            },
            {
                number: 9,
                name: "Limonade Zam Zam",
                desc: "Limonade Zam Zam",
                pic: require("../images/limonade.jpg"),
                radius: 50,
                price: "12 dhs"
            },
            {
                number: 10,
                name: "Saffron Tea",
                desc: "Saffron tea",
                pic: require("../images/tea.jpg"),
                radius: 50,
                price: "30 dhs"
            },
        ],
        waterList:
        [
            {
                number: 11,
                name: "Sparkling Water",
                desc: "Soda water (Sparkling water)",
                pic: require("../images/sparkling.jpg"),
                radius: 50,
                price: "10 dhs",
            },
            {
                number: 12,
                name: "Small Drinking Water",
                desc: "Small Drinking Water",
                pic: require("../images/small-water.jpg"),
                radius: 50,
                price: "5 dhs"
            },
            /* {
                name: "Big Mineral Water",
                desc: "Big Mineral Water",
                pic: require("../images/jig.png"),
                radius: 50,
                price: "10 dhs"
            }, */
        ],
        drinksList: [

            {
                number: 13,
                name: "Orange Juice",
                desc: "Orange Juice",
                pic: require("../images/Orange.jpg"),
                radius: 70,
                width: 125,
                marginLeft: 20,
                price: "25 dhs",
            },
            {
                number: 14,
                name: "Cherry Juice",
                desc: "Cherry Juice",
                pic: require("../images/Cherry.jpg"),
                radius: 70,
                width: 125,
                marginLeft: 20,
                price: "20 dhs",
            }
        ]
};

export default menu;


