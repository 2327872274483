import React, { useState, useEffect, useRef } from "react";


import { useNavigate } from "react-router-dom";

import Modal from 'react-modal';


import FlatList from 'flatlist-react';

/* import { Slide } from 'react-slideshow-image'; */
/* import 'react-slideshow-image/dist/styles.css' */

//import aclib from '//acscdn.com/script/aclib.js';



//import { NavLink } from "react-router-dom";
//import { Link } from "react-router-dom";


// Axios and Iframe

import axios from "axios";

import Iframe from "react-iframe";

import Footer from "./Footer";
import Header from "./Header";
//import About from "./About";


// Images

import Logo from "../images/jigarchi-log.png";

import bakke from "../images/bakke.gif";

import titleImg from "../images/title.png";

import shishlick from "../images/Shishlick.png";
import Sultani from "../images/Sultani.png";
import Chenjeh from "../images/Chenjeh.png";
import Bakhtiari from "../images/Bakhtiari.png";
import Vaziri from "../images/Vaziri.png";

import Heart from "../images/heart.png";

import jig from "../images/jig.png";

import search from "../images/close.svg";

import searchIcon from "../images/search-icon.png";
import closeIcon from "../images/closex.png";


// Menu Data

import menu from "../data/menu-last";







/// MAIN FUNCTION
///
//

function LandingPage() {

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();


  const [activeKey, setActiveKey] = useState(0);

  //const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [daysFrom, setDaysFrom] = useState(0);

  const [guestNum, setGuestNum] = useState("");

  const [liversSelect, setLiversSelect] = useState(true);
  const [kebabsSelect, setKebabsSelect] = useState(true);
  const [sidesSelect, setSidesSelect] = useState(true);
  const [drinksSelect, setDrinksSelect] = useState(true);

  const [searchVal, setSearchVal] = useState("");
  const [notFound, setNotFound] = useState(false);

  const [showAboutUs, setShowAboutUs] = useState(false);

  const [cmntrName, setCmntrName] = useState("");
  const [cmntrDesc, setCmntrDesc] = useState("");
  const [cmntrLastID, setCmntrLastID] = useState(0);

  const [comments, setComments] = useState([]);

  const hrt = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];

  const [heartList, setHeartList] = useState(hrt);

  const [showSearch, setShowSearch] = useState(false);

  const [leaveComment, setLeaveComment] = useState(false);

  const [randPic, setRandPic] = useState(0);


  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [modalPic, setModalPic] = React.useState(shishlick);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }




  const renderJigarItems = (data, key) => {
    return (
        

                <div
                        style={{
                          backgroundColor: "white", //"#99d8e0",
                          maxWidth: 500,
                          width: "75vw",
                          minWidth: "300px",
                          //height: 500,
                          padding: 10,
                          marginBottom: 0,
                          borderRadius: 8,
                          margin: 10,
                          borderWidth: 0.8,
                          borderColor: "lightgrey",
                          borderStyle: "solid",
                        }}
                      >

                  

                        <div style={{
                          //display: "flex",
                          marginTop: "20px",
                          
                        }}>



                        {/* <img
                          src={Heart}
                          alt="Jigarchi"
                          
                          style={{
                            //maxWidth: "100%",
                            width: "37px",
                            position: "absolute",
                            //width: "150px",
                            //maxHeight: 350,
                            marginTop: "5px",
                            marginLeft: windowSize.innerWidth > 1000 ? "325px" : "265px",
                            filter: heartList[key] === 0 ?
                            "opacity(0.2) drop-shadow(0 0 0 gray)"
                            :
                            "opacity(1) drop-shadow(0 0 0 red)",
                            cursor: "pointer",
                            //marginBottom: -40,
                            //marginLeft: 180
                            //animation: "rotation 100s infinite linear",
                            //borderRadius: "20px 20px 0px 0px",
                            //border: "5px solid #555",
                            //marginBottom: -800,
                            //opacity: 0.2,
                            //animation: "rotation 100s infinite linear"
                          }}

                          onClick={() => {
                            let newState = [...heartList];
                            if (newState[key] === 0)
                              newState[key] = 1;
                            else
                              newState[key] = 0;
                            setHeartList(newState)
                          }}
                        /> */}
                        {/* {<p style={{color: "black"}}>{heartList[key]}</p>} */}
                        <img
                          src={data.pic}
                          alt="Jigarchi"
                          onClick={() => {
                            setIsOpen(true);
                            setModalPic(data.pic);
                          }}
                          loading="lazy"
                          style={false ?{
                            maxWidth: "100%",
                            width: "200px",
                            marginBottom: 10,
                            
                          }:
                          {
                            maxWidth: "100%",
                            width: "300px",
                            //width: "150px",
                            //maxHeight: 350,
                            //marginLeft: "2vw",
                            marginBottom: 10,
                            animation: "rotation 100s infinite linear",
                            //borderRadius: "20px 20px 0px 0px",
                            //border: "5px solid #555",
                            //marginBottom: -800,
                            //opacity: 0.2,
                            //animation: "rotation 100s infinite linear"
                            
                          }
                        }
                        />

                        
                        
                        </div>


                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            //flexWrap: "wrap",
                            justifyContent: "space-between",
                            //alignItems: "stretch",
                            //columnGap: "0rem",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "23px",
                              fontWeight: "300",
                              color: "#9C1006",
                              marginTop: -300,
                              width: "20%",
                            }}
                          >
                            {/* {data.number} */}
                            {Number(key)+1}
                          </p>

                          <p
                            style={{
                              fontSize: "16.5px",
                              fontWeight: "800",
                              color: "#000000",
                              marginTop: 0,
                              width: "80%",
                            }}
                          >
                            {data.name}
                          </p>

                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "700",
                              color: "#9C1006",
                              marginTop: 0,
                              width: "25%",
                            }}
                          >
                            {data.price}
                          </p>
                        </div>

                        <div style={{
                          width: "90%",
                          height: "0.5px",
                          backgroundColor: "gray",
                          marginBottom: "20px",
                          marginLeft: "6%",
                        }}>

                        </div>

                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "300",
                            fontStyle: "italic",
                            color: "#9C1006",
                            marginTop: -10,
                            maxWidth: 500,
                            marginLeft: 40,
                            textAlign: "left",
                          }}
                        >
                          {data.desc}
                        </p>
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "300",
                            fontStyle: "italic",
                            color: "#9C1006",
                            marginTop: -10,
                            maxWidth: 500,
                            marginLeft: 40,
                            textAlign: "left",
                          }}
                        >
                          {data.moreDesc}
                        </p>

                        {/* <p style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      color: "#9C1006",
                      marginTop: 0,
                      width: "100%"
                    }}>
                      {data.number} -- {data.name} -- {data.price}
                    </p> */}
                      </div>
        
        
    );
  }

    

  function getComments() {

    /* let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;

    var date1 = new Date("10/10/2022");
    var date2 = new Date(today);

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    setDaysFrom(Difference_In_Days.toString()); */

    axios
      .get("https://jigarchibackend.onrender.com/comment")

      .then((response) => {
        // Handle the response data

        setComments(response.data);
      })

      .catch((error) => {
        // Handle any errors

        console.error("Error:", error);
      });

  }

  function addComment() {

    let today = new Date();

    

    axios
      .post(
        "https://jigarchibackend.onrender.com/comment/add", {
          ID: (Number(comments[comments.length-1].ID)+1).toString(),
          name: cmntrName,
          comment: cmntrDesc,
          date: today,
          rate: 5,
          reviewed: true,
        }
      )

      .then((response) => {
        // Handle the response data
        //setGuestNum(response.data);
        console.log(response);
        setCmntrDesc("");
        setCmntrName("");
        setLeaveComment(false);
      })

      .catch((error) => {
        // Handle any errors

        console.error("Error:", error);
      });

  }


    function getVisitNumber() {

      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = mm + "/" + dd + "/" + yyyy;

      var date1 = new Date("10/10/2022");
      var date2 = new Date(today);

      // To calculate the time difference of two dates
      var Difference_In_Time = date2.getTime() - date1.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      setDaysFrom(Difference_In_Days.toString());

      axios
        .get("https://jigarchibackend.onrender.com/counter")

        .then((response) => {
          // Handle the response data

          setGuestNum(response.data[0].todayCount);
        })

        .catch((error) => {
          // Handle any errors

          console.error("Error:", error);
        });

    }

    function increaseVisitNumber() {

      axios
        .get(
          "https://jigarchibackend.onrender.com/counter/increase/64e878aaa8e137001d0e5a98"
        )

        .then((response) => {
          // Handle the response data
          //setGuestNum(response.data);
        })

        .catch((error) => {
          // Handle any errors

          console.error("Error:", error);
        });

    }

    function getWindowSize() {
      const { innerWidth, innerHeight } = window;
      return { innerWidth, innerHeight };
    }


    function randomIntFromInterval(min, max) { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min)
    }



    /* const adElement = () => {
      var dataInside = 'Some Adsssss'
      return <div><p>{dataInside}</p></div>
    } */


    function Welcome(props) {
      /* window.aclib.runBanner({
          zoneId: '8228822',
      }); */
      /* aclib.runBanner({
        zoneId: '8228822',
      }) */

      /* window.aclube.runBanner({
        zoneId: '8228822',
      }) */
      
      return <h1>Hello, {props.name}</h1>;
    }


    const adMark = () => {
      return { __html: `
      <iframe 
        data-aa='2314735' 
        src='//ad.a-ads.com/2314735?size=320x100'
        style=' 
            width:320px; 
            height:100px;

            border: 0;

            margin-top: 10px;

            overflow:hidden; 
            background-color: "transparent";
            '
        >
        </iframe>
      ` }
    }

    const adMarkBig = () => {
      return { __html: `
      <iframe 
        data-aa='2315145' 
        src='//ad.a-ads.com/2315145?size=728x90'
        style=' 
            width:728px; 
            height:90px;

            margin-bottom: 10px;

            overflow:hidden; 
            background-color: "transparent";
            '
        >
        </iframe>
      ` }
    }
    


    //let aclube;
    
    useEffect(() => {

      //aclube = require('../data/aclib');

      //console.log(aclube);

      getVisitNumber();

      getComments();


      increaseVisitNumber();

      const rndInt = randomIntFromInterval(1, 12);

      setRandPic(rndInt);   
      
      


      function handleWindowResize() {
        setWindowSize(getWindowSize());
      }

      window.addEventListener("resize", handleWindowResize);

      return () => {
        window.removeEventListener("resize", handleWindowResize);

      };


    }, []);



    useEffect(() => {


    }, [activeKey]);


    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };


  return (

    <>
    <header className="App-header">

      <div>
        

        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              height: "74vh",
              minHeight: "800px",
              width: "44vw",
              maxWidth: 1200,
              
            }}
          >
            
          </div>

          
          
        </div> */}

        <div style={{
         /*  width: "90vw",
          maxWidth: 1200, */
          //width: "100%"
        }}>
          
          <div
            //className="App-header-container"
            style={{
              backgroundColor:" #99d8e0",
              backgroundImage: `url(${bakke})`,
              height: "1000px",
              backgroundSize: "cover",
              marginBottom: "-800px",
              borderRadius: "20px",
              marginTop: "2px",
              //width: "85vw"
            }}

          >
            <div
              src={bakke}
              alt="Jigarchi"
              style={{   
                height: "100%",
                //maxHeight: 941,
                /* width: "100vw",
                maxWidth: 1200, */
                backgroundColor: "rgba(153,216,224, 0.6)",
              }}
            ></div>
          </div>

        </div>

        <div style={{
          //backgroundColor: "red",
          width: "100%",
          
          height: windowSize.innerWidth > 850
          ? 800
          : 1100,
          display: "flex",
          flexDirection: windowSize.innerWidth > 850
          ? "row"
          : "column",
        }}>

          

          <div style={{
            //backgroundColor: "blue",
            width: windowSize.innerWidth > 850
            ? "50%"
            : "100%",
            height: "100%",
            display: "flex",
            flexDirection:"column",
            alignItems: "center",
            //justifyContent: "center",
          }}>

          <img
              src={Logo}
              alt="Jigarchi"
              style={{
                width: "40vw",
                minWidth: "120px",
                maxWidth: "250px",
                opacity: 1,
                
                marginTop: -90,
                
              }}
            />

            <div style={{
              
            }}>
            <div
              style={{
                marginLeft: windowSize.innerWidth > 1400
                ? "10vw"
                : "10vw",
                marginTop: -30,
                //textAlign: "left",
                width: '100%'
              }}
            >
              <p
                style={{
                  fontSize: windowSize.innerWidth < 850 || windowSize.innerHeight < 700
                  ? "40px"
                  : "60px",
                  fontWeight: "200",
                  marginTop: "30px",
                  marginLeft: 0,
                  textAlign: windowSize.innerWidth > 850 ? "left" : "center",
                  width: "80%"
                }}
              >
                Welcome to
                Jigarchi
              </p>
              <p
                style={{
                  fontSize: windowSize.innerWidth < 850 || windowSize.innerHeight < 700
                  ? "17px"
                  : "21px",
                  fontWeight: "300",
                  marginTop: windowSize.innerWidth < 1100 || windowSize.innerHeight < 700
                  ? -30
                  : -30,
                  width: "80%",
                  //marginLeft: "40%",
                  textAlign: "left",
                }}
              >
                Jigarchi is the first Charbroiled Liver Shop in Dubai,
                <br/>
                Enjoy your top quality persian kebab and liver in a very luxury environment
              </p>
            </div>

            <div
              style={{
                width: 150,
                //maxWidth: 200,
                height: "50px",
                marginLeft: "10vw",
                marginTop: 5,
                backgroundColor: "#9C1006",
                border: "3.5px solid #9C1006",
                borderRadius: 10,
                cursor: "pointer",
                
              }}

              //onClick={executeScroll}
              onClick={() => {
                setShowAboutUs(true);
              }}
            >
              <p
                style={{
                  marginTop: 8,
                  marginLeft: 3,
                  fontSize: "25px",
                  fontWeight: "200",

                  //color: selectedCategory == key ? "white" : "#9C1006"
                }}
              >
                Read More
              </p>
            
            </div>
            </div>

          </div>

          <div style={{
            //backgroundColor: "green",
            width: windowSize.innerWidth > 850
            ? "50%"
            : "100%",
            height: "100%",
            display: "flex",
            //flexDirection:"row",
            alignItems: "center",
            justifyContent: "center",
            //flexWrap: "wrap",

          }}>


            <img
              src={menu.jigarList[randPic].pic}
              alt="Banner"
              style={{
                width: "30vw",
                minWidth: "250px",
                maxWidth: "400px",
                opacity: 1,
                marginTop: -400,
                //marginLeft: 0,
                animation: "rotation 50s infinite linear",

              }}
            />
            
          

          </div>


        </div>
      </div>





      {
        showAboutUs
        ?
        <div>
        <div

          
          
          style={{
            //display: "flex",
            //flexDirection: "row",
            backgroundColor: "#ffffff",
            marginTop: windowSize.innerWidth > 850
            ? "-100px"
            : "-300px",
            display: "grid",
            gridTemplateColumns:
              windowSize.innerWidth > 1024
                ? "repeat(2, 1fr)"
                : "repeat(1, 1fr)",
            marginBottom: "0px",
            width: "90vw",
            maxWidth: 1200,
          }}
        >
          <div
            style={{
              //height: windowSize.innerHeight,
              //width: "44vw",
              maxWidth: 1200,
            }}
          >
            <div
              style={{
                marginLeft: "-25vw",
                marginTop: 60,
              }}
            >
              <div>
                <img
                  src={Sultani}
                  alt="Jigarchi"
                  style={{
                    width: windowSize.innerWidth > 1024
                      ? "20vw"
                      : "40vw",
                    maxWidth: "300px",
                    minWidth: "100px",
                    marginLeft: "20vw",
                    marginTop: 0,
                    animation: "rotation 100s infinite linear",
                  }}
                />

                <img
                  src={Chenjeh}
                  alt="Jigarchi"
                  style={{
                    width: windowSize.innerWidth > 1024
                      ? "14vw"
                      : "28vw",
                    maxWidth: "200px",
                    minWidth: "70px",
                    //minWidth: "150px",
                    marginLeft: "2vw",
                    marginTop: 0,
                    animation: "rotation 100s infinite linear",
                  }}
                />
              </div>

              <div>
                <img
                  src={Bakhtiari}
                  alt="Jigarchi"
                  style={{
                    width: windowSize.innerWidth > 1024
                      ? "14vw"
                      : "28vw",
                    maxWidth: "200px",
                    minWidth: "70px",
                    //minWidth: "150px",
                    marginLeft: windowSize.innerWidth > 1600
                    ? "24vw"
                    : "28vw",
                    marginTop: "2vw",
                    animation: "rotation 100s infinite linear",
                  }}
                />
                <img
                  src={Vaziri}
                  alt="Jigarchi"
                  style={{
                    width: windowSize.innerWidth > 1024
                      ? "20vw"
                      : "40vw",
                    maxWidth: "300px",
                    minWidth: "100px",
                    //minWidth: "200px",
                    marginLeft: "2vw",
                    marginBottom: "-6.5vw",
                    animation: "rotation 100s infinite linear",
                  }}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              //marginLeft: "51%",
              marginTop: "5vh",
              marginBottom: "10vh",
              width: windowSize.innerWidth > 1024
                ? "50vw"
                : "90vw",
              maxWidth: "600px"
              //width: "40%"
            }}
          >
            <p
              style={{
                fontSize: "40px",
                fontWeight: "100",
                color: "#252525",
                marginLeft: "5%",
                textAlign: "left",
                color: "#9C1006",
              }}
            >
              about us
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: -40,
                //width: "30vw"
              }}
            >
              <p
                style={{
                  fontSize: "30px",
                  fontWeight: "800",
                  color: "#252525",
                  marginLeft: "5%",
                  textAlign: "left",
                }}
              >
                Welcome to
              </p>
              <img
                src={titleImg}
                alt="Jigar"
                style={{
                  //width: "200px",
                  //width: "20vw",
                  width: "30vw",
                  height: "auto",
                  //height: "auto",
                  maxWidth: "400px",
                  minWidth: "220px",
                  //opacity: 1,
                  marginTop: -30,
                  marginLeft: 20
                }}
              />
              {/* <p
                style={{
                  fontSize: "30px",
                  fontWeight: "800",
                  color: "#252525",
                  //marginLeft: "100px",
                  textAlign: "left",
                }}
              >
                Resturant
              </p> */}
            </div>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                margin: 20,
                //width: "37vw",
                //marginLeft: "52%",
                textAlign: "left",
                color: "#252525",
              }}
            >
              Jigarchi Restaurant serves Kebab and Charboiled Liver for everybody in Dubai.
              Kebab were cooked for the first time in the Qajar Kingdom era. the King
              Nasser al-Din Shah has loved Kebab the most, which originally is a Caucasus Food.
              Although the Iranian type of kebab should be cook with veal or mutton.
            </p>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                margin: 20,
                //width: "37vw",
                //marginLeft: "52%",
                textAlign: "left",
                color: "#252525",
              }}
            >
              We have opened Jigarchi restaurant {daysFrom} days ago. Be with us
              and have a nice meal. We love you and we all love kebabs.
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: windowSize.innerWidth > 500
                ? "row"
                : "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    width: 5,
                    height: 70,
                    backgroundColor: "#9C1006",
                  }}
                ></div>
                <p
                  style={{
                    fontSize: "50px",
                    fontWeight: "800",
                    marginTop: 3,

                    //width: "37vw",
                    marginLeft: "20px",
                    textAlign: "left",
                    color: "#9C1006",
                  }}
                >
                  {guestNum}
                </p>

                <div>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      marginTop: 10,
                      //width: "37vw",
                      marginLeft: "20px",
                      textAlign: "left",
                      color: "#252525",
                    }}
                  >
                    Your today's
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      marginTop: 10,
                      //width: "37vw",
                      marginLeft: "20px",
                      textAlign: "left",
                      color: "#252525",
                    }}
                  >
                    Guest Number
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "50px",
                }}
              >
                <div
                  style={{
                    width: 5,
                    height: 70,
                    backgroundColor: "#9C1006",
                  }}
                ></div>
                <p
                  style={{
                    fontSize: "50px",
                    fontWeight: "800",
                    marginTop: 3,

                    //width: "37vw",
                    marginLeft: "20px",
                    textAlign: "left",
                    color: "#9C1006",
                  }}
                >
                  {daysFrom}
                </p>

                <div>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      marginTop: 10,
                      //width: "37vw",
                      marginLeft: "20px",
                      textAlign: "left",
                      color: "#252525",
                    }}
                  >
                    Days from
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      marginTop: 10,
                      //width: "37vw",
                      marginLeft: "20px",
                      textAlign: "left",
                      color: "#252525",
                    }}
                  >
                    Start Day
                  </p>
                </div>
              </div>
            </div>

            <div
              style={{
                width: 170,
                //maxWidth: 200,
                height: "50px",
                marginLeft: "5px",
                marginTop: -10,
                backgroundColor: "#9C1006",
                border: "3.5px solid #9C1006",
                borderRadius: 10,
                cursor: "pointer",
              }}

              onClick={() => {
                setShowAboutUs(false);
              }}
            >
              <p
                style={{
                  marginTop: 8,
                  marginLeft: 3,
                  fontSize: "25px",
                  fontWeight: "200",

                  //color: selectedCategory == key ? "white" : "#9C1006"
                }}
              >
                Hide About Us
              </p>
            </div>
          </div>

          

        </div>

        

        </div>
        :
        false
      }
      




      

      

      
      


    

      <div
        style={{
          //height: 1200,
          width: "90vw",
          maxWidth: 1200,
          marginTop: !showAboutUs ? -150 : 0,

        }}

        ref={myRef}
      >






<header style={windowSize.innerWidth > 660 ? {
        /* ...style,  */
        zIndex: "999"} : {}}>
              <div style={{
                backgroundColor: "rgba(153,216,224)",
                width: "100%",
                height: windowSize.innerWidth > 660 ? showSearch ? "150px" : "75px" : showSearch ? "330px" : "255px",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                marginTop: windowSize.innerWidth > 720 ? 0 : showAboutUs ? -100 : -150,

                //borderRadius: "20px 20px 0px 0px",
                /* position: "sticky",
                position: "-webkit-sticky",
                top: 0,
                zIndex: "1", */
              }}>

        

          
        
      <div style={{
            /* display: "block",
            marginLeft: "auto",
            marginRight: "auto", */
            //width: "50%",
            //minWidth: "700px",
            
          }}
          >

          

          <div style={
            windowSize.innerWidth > 660
            ?
            {

              display: "flex",
              //alignItems: "center",
              flexDirection: "row",
              
              
              //top: 0,
              //padding: "5px"
            }
            :
            {
              display: "flex",
              //alignItems: "center",
              flexDirection: "column",

            }}>

            <div 
            onClick={() => {
              setShowSearch(!showSearch);
              
            }}
            style={{
              position: "absolute",
              
              cursor: "pointer"
            }}>
              {showSearch
              ?
              <img style={{
                marginLeft: -100,
                marginTop: 20,
                width: "50px",
                filter: "invert(100%)"
              }}
              src={closeIcon}>
              </img>
              :
              <img style={{
                marginLeft: -100,
                marginTop: 25,
                width: "40px",
                //color: "white",
                filter: "invert(100%)"
              }}
              src={searchIcon}>
              </img>
              }
            </div>

            {
            ["LIVERS", "KEBABS", "SIDE DISHES", "DRINKS"].map((data, key) => (
            <div 
              onClick={() => {

                if (key == 0)
                {
                setLiversSelect(true);
                setKebabsSelect(false);
                setSidesSelect(false);
                setDrinksSelect(false);
                }

                if (key == 1)
                {
                setLiversSelect(false);
                setKebabsSelect(true);
                setSidesSelect(false);
                setDrinksSelect(false);
                }
                
                if (key == 2)
                {
                setLiversSelect(false);
                setKebabsSelect(false);
                setSidesSelect(true);
                setDrinksSelect(false);
                }

                if (key == 3)
                {
                setLiversSelect(false);
                setKebabsSelect(false);
                setSidesSelect(false);
                setDrinksSelect(true);
                }

                setSearchVal("");
 
              }}
              
             style={{
                //width: "100%",
                minWidth: "120px",
                maxWidth: "200px",
                height: "40px",
                //backgroundColor: "#9C1006", //"rgba(153,216,224)",
                margin: 10,
                /* borderRadius: "25px",
                borderColor: "white",
                borderWidth: "20px",
                borderWidth: "5px",
                borderStyle: "solid", */
                cursor: "pointer"
              }}>

                <p style={{
                  color: (key == 0 && liversSelect)
                  ||
                  (key == 1 && kebabsSelect)
                  ||
                  (key == 2 && sidesSelect)
                  ||
                  (key == 3 && drinksSelect)
                  ? "white" : "white",
                  marginTop:"5px",
                  fontSize: "34px",
                  fontWeight: "400",
                  textAlign: "center",
                  marginTop: "10px"
                }}>
                  {data}
                  
                </p>

                {
                  (key == 0 && liversSelect)
                  ||
                  (key == 1 && kebabsSelect)
                  ||
                  (key == 2 && sidesSelect)
                  ||
                  (key == 3 && drinksSelect)
                  ?
                  <div style={{
                    backgroundColor: "white",
                    //width: "100%",
                    height: "3px",
                    borderRadius: "30px",
                    marginTop: "-35px",
                  }}>
  
                  </div>
                  :
                  false
                }
                

            </div>
            ))
            }

            
          </div>

            

          </div>

          
        <div style={{
          position: "relative",
          marginTop: 10
        }}>
          <div 
            onClick={() => {
              setSearchVal("");
            }}
            style={{
            height: "20px",
            width: "28px",
            //backgroundColor: "red",
            padding: "2px",
            position: "absolute",
            boxSizing:"border-box",
            //top:"57%",
            top: "20%",
            left:"6px",
            //transform: "translateY(-50%)",
            cursor: "pointer"
          }}>

            {
              searchVal !== ""
              ?
              <img src={search}
                style={{
                  width: "100%",
                  filter: "opacity(0.5) drop-shadow(0 0 0 red)",
                  marginLeft: "20px"
                }}
                >
              </img>
              :
              false


            }
            

          </div>

          {
          showSearch
          ?
          <input
            type="text"
            value={searchVal}
            placeholder= "Search in Jigarchi Menu..."
            onChange={(e) => {
              setSearchVal(e.target.value);
              setLiversSelect(true);
              setKebabsSelect(true);
              setSidesSelect(true);
              setDrinksSelect(true);
              


              
            }}
            style={{
              width: "65vw",
              maxWidth: "800px",
              height: 200,
              fontSize: "18px",
              fontWeight: "700",
              color: "#9C1006",
              borderColor: "white",
              borderWidth: "20px",
              borderWidth: "5px",
              borderStyle: "solid",
              borderRadius: "13px",
              marginTop: "10px",
              marginLeft: "20px",
              height: "50px",
              boxSizing: "border-box",
              paddingLeft: "2rem",
              //backgroundColor: 'rgba(0,0,0,0)',
            }}
         />
          :
          false
          }
        </div>  

          



          

          
          

              </div>
            </header>

      
            {
          //!liversSelect && !kebabsSelect && !sidesSelect && !drinksSelect
            notFound
            ?

        
            <div style={{
              //backgroundColor: "red"
            }}>
              <img
                  src={Logo}
                  alt="Jigarchi"
                  style={{
                    //width: "400px",
                    minWidth: "120px",
                    maxWidth: "500px",
                    //opacity: 1,
                    marginTop: 70,
                    
                  }}
                />
            </div>

            :
            false
            }



        

          <div style={{
            backgroundColor: "#99d8e0",
            
          }}>

            <div
              style={{
                //marginLeft: "25%",
                alignItems: "center",
                //marginTop: 10,
                flexDirection: "column",
                display: "flex",
                //height: 950,   /// later adfix
                overflow: "scroll",
                scrollbarWidth: "none",
                borderRadius: "15px",
                backgroundColor: "white"
                
              }}
            >

          
              {[menu.jigarList]
              .filter(item => liversSelect)
              .map((d, k) => {
                return (

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        windowSize.innerWidth > 1024
                          ? "repeat(2, 1fr)"
                          : "repeat(1, 1fr)",
                      marginBottom: "0px",
                    }}
                  >
                    <FlatList
                      list={menu.jigarList}
                      renderItem={renderJigarItems}
                      renderWhenEmpty={() => <div>List is empty!</div>}
                      initialNumToRender={2}
                      filterBy={item => item.name.toLowerCase().includes(searchVal.toLowerCase())}
                      /* limit={3} */
                      /* group={{
                          limit: 3
                      }} */
                      //maxToRenderPerBatch={2}
                      //windowSize={2}
                      //sortBy={["firstName", {key: "lastName", descending: true}]}
                      //groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
                    />
                    
                  </div>
                );
              })}

              {[menu.kebabList]
              .filter(item => kebabsSelect)
              .map((d, k) => {
                return (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        windowSize.innerWidth > 1024
                          ? "repeat(2, 1fr)"
                          : "repeat(1, 1fr)",
                      marginBottom: "0px",
                    }}
                  >
                    {d
                    .filter(item => item.name.toLowerCase().includes(searchVal.toLowerCase()))
                    .map((data, key) => {
                      return (
                        <div
                          style={{
                            backgroundColor: "white", //"#99d8e0",
                            maxWidth: 500,
                            width: "40vw",
                            minWidth: "300px",
                            //height: 500,
                            padding: 10,
                            marginBottom: 0,
                            borderRadius: 8,
                            margin: 10,
                            borderWidth: 0.8,
                            borderColor: "lightgrey",
                            borderStyle: "solid",
                          }}
                        >

                    

                          <div style={{
                            //display: "flex",
                            marginTop: "20px",
                            
                          }}>



                          {/* <img
                            src={Heart}
                            alt="Jigarchi"
                            
                            style={{
                              //maxWidth: "100%",
                              width: "37px",
                              position: "absolute",
                              //width: "150px",
                              //maxHeight: 350,
                              marginTop: "10px",
                              marginLeft: "175px",
                              filter: heartList[key] === 0 ?
                              "opacity(0.2) drop-shadow(0 0 0 gray)"
                              :
                              "opacity(1) drop-shadow(0 0 0 red)",
                              cursor: "pointer",
                              //marginBottom: -40,
                              //marginLeft: 180
                              //animation: "rotation 100s infinite linear",
                              //borderRadius: "20px 20px 0px 0px",
                              //border: "5px solid #555",
                              //marginBottom: -800,
                              //opacity: 0.2,
                              //animation: "rotation 100s infinite linear"
                            }}

                            onClick={() => {
                              let newState = [...heartList];
                              if (newState[key] === 0)
                                newState[key] = 1;
                              else
                                newState[key] = 0;
                              setHeartList(newState)
                            }}
                          /> */}
                          {/* {<p style={{color: "black"}}>{heartList[key]}</p>} */}
                          <img
                            src={data.pic}
                            alt="Jigarchi"
                            onClick={() => {
                              setIsOpen(true);
                              setModalPic(data.pic);
                            }}
                            loading="lazy"
                            style={false ?{
                              maxWidth: "100%",
                              width: "200px",
                              marginBottom: 10,
                              
                            }:
                            {
                              maxWidth: "100%",
                              width: "300px",
                              //width: "150px",
                              //maxHeight: 350,
                              //marginLeft: "2vw",
                              marginBottom: 10,
                              animation: "rotation 100s infinite linear",
                              
                              //borderRadius: "20px 20px 0px 0px",
                              //border: "5px solid #555",
                              //marginBottom: -800,
                              //opacity: 0.2,
                              //animation: "rotation 100s infinite linear"
                              
                            }
                          }
                          />

                          
                          
                          </div>


                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              //flexWrap: "wrap",
                              justifyContent: "space-between",
                              //alignItems: "stretch",
                              //columnGap: "0rem",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "23px",
                                fontWeight: "300",
                                color: "#9C1006",
                                marginTop: -300,
                                width: "20%",
                              }}
                            >
                              {/* {data.number} */}
                              {key+1}
                            </p>

                            <p
                              style={{
                                fontSize: "16.5px",
                                fontWeight: "800",
                                color: "#000000",
                                marginTop: 0,
                                width: "80%",
                              }}
                            >
                              {data.name}
                            </p>

                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "700",
                                color: "#9C1006",
                                marginTop: 0,
                                width: "25%",
                              }}
                            >
                              {data.price}
                            </p>
                          </div>

                          <div style={{
                            width: "90%",
                            height: "0.5px",
                            backgroundColor: "gray",
                            marginBottom: "20px",
                            marginLeft: "6%",
                          }}>

                          </div>

                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "300",
                              fontStyle: "italic",
                              color: "#9C1006",
                              marginTop: -10,
                              maxWidth: 500,
                              marginLeft: 40,
                              textAlign: "left",
                            }}
                          >
                            {data.desc}
                          </p>

                          {/* <p style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#9C1006",
                        marginTop: 0,
                        width: "100%"
                      }}>
                        {data.number} -- {data.name} -- {data.price}
                      </p> */}
                        </div>
                      );
                    })}
                  </div>
                );
              })}

              {/* {[menu.qqList]
              .filter(item => kebabsSelect)
              .map((d, k) => {
                return (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        windowSize.innerWidth > 1024
                          ? "repeat(2, 1fr)"
                          : "repeat(1, 1fr)",
                      marginBottom: "0px",
                    }}
                  >
                    {d
                    .filter(item => item.name.toLowerCase().includes(searchVal.toLowerCase()))
                    .map((data, key) => {
                      return (
                        <div
                          style={{
                            backgroundColor: "white", //"#99d8e0",
                            maxWidth: 500,
                            width: "40vw",
                            minWidth: "300px",
                            //height: 500,
                            padding: 10,
                            marginBottom: 0,
                            borderRadius: 8,
                            margin: 10,
                            borderWidth: 0.8,
                            borderColor: "lightgrey",
                            borderStyle: "solid",
                          }}
                        >

                    

                          <div style={{
                            //display: "flex",
                            marginTop: "20px",
                            
                          }}>



                          {/* <img
                            src={Heart}
                            alt="Jigarchi"
                            
                            style={{
                              //maxWidth: "100%",
                              width: "37px",
                              position: "absolute",
                              //width: "150px",
                              //maxHeight: 350,
                              marginTop: "10px",
                              marginLeft: "175px",
                              filter: heartList[key] === 0 ?
                              "opacity(0.2) drop-shadow(0 0 0 gray)"
                              :
                              "opacity(1) drop-shadow(0 0 0 red)",
                              cursor: "pointer",
                              //marginBottom: -40,
                              //marginLeft: 180
                              //animation: "rotation 100s infinite linear",
                              //borderRadius: "20px 20px 0px 0px",
                              //border: "5px solid #555",
                              //marginBottom: -800,
                              //opacity: 0.2,
                              //animation: "rotation 100s infinite linear"
                            }}

                            onClick={() => {
                              let newState = [...heartList];
                              if (newState[key] === 0)
                                newState[key] = 1;
                              else
                                newState[key] = 0;
                              setHeartList(newState)
                            }}
                          /> */}
                          {/* {<p style={{color: "black"}}>{heartList[key]}</p>} *
                          <img
                            src={data.pic}
                            alt="Jigarchi"
                            onClick={() => {
                              setIsOpen(true);
                              setModalPic(data.pic);
                            }}
                            loading="lazy"
                            style={false ?{
                              maxWidth: "100%",
                              width: "200px",
                              marginBottom: 10,
                              
                            }:
                            {
                              maxWidth: "100%",
                              width: "200px",
                              //width: "150px",
                              //maxHeight: 350,
                              //marginLeft: "2vw",
                              marginBottom: 10,
                              borderRadius: "20px",
                              //animation: "rotation 100s infinite linear",
                              //borderRadius: "20px 20px 0px 0px",
                              //border: "5px solid #555",
                              //marginBottom: -800,
                              //opacity: 0.2,
                              //animation: "rotation 100s infinite linear"
                              
                            }
                          }
                          />

                          
                          
                          </div>


                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              //flexWrap: "wrap",
                              justifyContent: "space-between",
                              //alignItems: "stretch",
                              //columnGap: "0rem",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "23px",
                                fontWeight: "300",
                                color: "#9C1006",
                                marginTop: -320,
                                width: "20%",
                              }}
                            >
                              {/* {data.number} *
                              {key+1}
                            </p>

                            <p
                              style={{
                                fontSize: "16.5px",
                                fontWeight: "800",
                                color: "#000000",
                                marginTop: 0,
                                width: "80%",
                              }}
                            >
                              {data.name}
                            </p>

                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "700",
                                color: "#9C1006",
                                marginTop: 0,
                                width: "25%",
                              }}
                            >
                              {data.price}
                            </p>
                          </div>

                          <div style={{
                            width: "90%",
                            height: "0.5px",
                            backgroundColor: "gray",
                            marginBottom: "20px",
                            marginLeft: "6%",
                          }}>

                          </div>

                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "300",
                              fontStyle: "italic",
                              color: "#9C1006",
                              marginTop: -10,
                              maxWidth: 500,
                              marginLeft: 40,
                              textAlign: "left",
                            }}
                          >
                            {data.desc}
                          </p>

                          {/* <p style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#9C1006",
                        marginTop: 0,
                        width: "100%"
                      }}>
                        {data.number} -- {data.name} -- {data.price}
                      </p> *
                        </div>
                      );
                    })}
                  </div>
                );
              })} */}

              {[menu.appetizerList]
              .filter(item => sidesSelect)
              .map((d, k) => {
                return (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        windowSize.innerWidth > 1024
                          ? "repeat(2, 1fr)"
                          : "repeat(1, 1fr)",
                      marginBottom: "0px",
                    }}
                  >
                    {d
                    .filter(item => item.name.toLowerCase().includes(searchVal.toLowerCase()))
                    .map((data, key) => {
                      return (
                        <div
                          style={{
                            backgroundColor: "white", //"#99d8e0",
                            maxWidth: 500,
                            width: "40vw",
                            minWidth: "300px",
                            //height: 500,
                            padding: 10,
                            marginBottom: 0,
                            borderRadius: 8,
                            margin: 10,
                            borderWidth: 0.8,
                            borderColor: "lightgrey",
                            borderStyle: "solid",
                          }}
                        >

                    

                          <div style={{
                            //display: "flex",
                            marginTop: "20px",
                            
                          }}>



                          {/* <img
                            src={Heart}
                            alt="Jigarchi"
                            
                            style={{
                              //maxWidth: "100%",
                              width: "37px",
                              position: "absolute",
                              //width: "150px",
                              //maxHeight: 350,
                              marginTop: "10px",
                              marginLeft: "175px",
                              filter: heartList[key] === 0 ?
                              "opacity(0.2) drop-shadow(0 0 0 gray)"
                              :
                              "opacity(1) drop-shadow(0 0 0 red)",
                              cursor: "pointer",
                              //marginBottom: -40,
                              //marginLeft: 180
                              //animation: "rotation 100s infinite linear",
                              //borderRadius: "20px 20px 0px 0px",
                              //border: "5px solid #555",
                              //marginBottom: -800,
                              //opacity: 0.2,
                              //animation: "rotation 100s infinite linear"
                            }}

                            onClick={() => {
                              let newState = [...heartList];
                              if (newState[key] === 0)
                                newState[key] = 1;
                              else
                                newState[key] = 0;
                              setHeartList(newState)
                            }}
                          /> */}
                          {/* {<p style={{color: "black"}}>{heartList[key]}</p>} */}
                          <img
                            src={data.pic}
                            alt="Jigarchi"
                            onClick={() => {
                              setIsOpen(true);
                              setModalPic(data.pic);
                            }}
                            loading="lazy"
                            style={false ?{
                              maxWidth: "100%",
                              width: "200px",
                              marginBottom: 10,
                              
                            }:
                            {
                              maxWidth: "100%",
                              width: "200px",
                              //width: "150px",
                              //maxHeight: 350,
                              //marginLeft: "2vw",
                              marginBottom: 10,
                              borderRadius: "20px",
                              //animation: "rotation 100s infinite linear",
                              //borderRadius: "20px 20px 0px 0px",
                              //border: "5px solid #555",
                              //marginBottom: -800,
                              //opacity: 0.2,
                              //animation: "rotation 100s infinite linear"
                              
                            }
                          }
                          />

                          
                          
                          </div>


                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              //flexWrap: "wrap",
                              justifyContent: "space-between",
                              //alignItems: "stretch",
                              //columnGap: "0rem",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "23px",
                                fontWeight: "300",
                                color: "#9C1006",
                                marginTop: -320,
                                width: "20%",
                              }}
                            >
                              {/* {data.number} */}
                              {key+1}
                            </p>

                            <p
                              style={{
                                fontSize: "16.5px",
                                fontWeight: "800",
                                color: "#000000",
                                marginTop: 0,
                                width: "80%",
                              }}
                            >
                              {data.name}
                            </p>

                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "700",
                                color: "#9C1006",
                                marginTop: 0,
                                width: "25%",
                              }}
                            >
                              {data.price}
                            </p>
                          </div>

                          <div style={{
                            width: "90%",
                            height: "0.5px",
                            backgroundColor: "gray",
                            marginBottom: "20px",
                            marginLeft: "6%",
                          }}>

                          </div>

                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "300",
                              fontStyle: "italic",
                              color: "#9C1006",
                              marginTop: -10,
                              maxWidth: 500,
                              marginLeft: 40,
                              textAlign: "left",
                            }}
                          >
                            {data.desc}
                          </p>

                          {/* <p style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#9C1006",
                        marginTop: 0,
                        width: "100%"
                      }}>
                        {data.number} -- {data.name} -- {data.price}
                      </p> */}
                        </div>
                      );
                    })}
                  </div>
                );
              })}

              {[menu.dooghList, menu.softDrinkList, menu.waterList, menu.drinksList]
              .filter(item => drinksSelect)
              .map((d, k) => {
                return (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        windowSize.innerWidth > 1024
                          ? "repeat(2, 1fr)"
                          : "repeat(1, 1fr)",
                      marginBottom: "0px",
                    }}
                  >
                    {d
                    .filter(item => item.name.toLowerCase().includes(searchVal.toLowerCase()))
                    .map((data, key) => {
                      return (
                        <div
                          style={{
                            backgroundColor: "white", //"#99d8e0",
                            maxWidth: 500,
                            width: "40vw",
                            minWidth: "300px",
                            //height: 500,
                            padding: 10,
                            marginBottom: 0,
                            borderRadius: 8,
                            margin: 10,
                            borderWidth: 0.8,
                            borderColor: "lightgrey",
                            borderStyle: "solid",
                          }}
                        >

                    

                          <div style={{
                            //display: "flex",
                            marginTop: "20px",
                            
                          }}>



                          {/* <img
                            src={Heart}
                            alt="Jigarchi"
                            
                            style={{
                              //maxWidth: "100%",
                              width: "37px",
                              position: "absolute",
                              //width: "150px",
                              //maxHeight: 350,
                              marginTop: "10px",
                              marginLeft: "175px",
                              filter: heartList[key] === 0 ?
                              "opacity(0.2) drop-shadow(0 0 0 gray)"
                              :
                              "opacity(1) drop-shadow(0 0 0 red)",
                              cursor: "pointer",
                              //marginBottom: -40,
                              //marginLeft: 180
                              //animation: "rotation 100s infinite linear",
                              //borderRadius: "20px 20px 0px 0px",
                              //border: "5px solid #555",
                              //marginBottom: -800,
                              //opacity: 0.2,
                              //animation: "rotation 100s infinite linear"
                            }}

                            onClick={() => {
                              let newState = [...heartList];
                              if (newState[key] === 0)
                                newState[key] = 1;
                              else
                                newState[key] = 0;
                              setHeartList(newState)
                            }}
                          /> */}
                          {/* {<p style={{color: "black"}}>{heartList[key]}</p>} */}
                          <img
                            src={data.pic}
                            alt="Jigarchi"
                            onClick={() => {
                              setIsOpen(true);
                              setModalPic(data.pic);
                            }}
                            loading="lazy"
                            style={false ?{
                              maxWidth: "100%",
                              width: "200px",
                              marginBottom: 10,
                              
                            }:
                            {
                              maxWidth: "100%",
                              width: "200px",
                              //width: "150px",
                              //maxHeight: 350,
                              //marginLeft: "2vw",
                              marginBottom: 10,
                              borderRadius: "20px",
                              //animation: "rotation 100s infinite linear",
                              //borderRadius: "20px 20px 0px 0px",
                              //border: "5px solid #555",
                              //marginBottom: -800,
                              //opacity: 0.2,
                              //animation: "rotation 100s infinite linear"
                              
                            }
                          }
                          />

                          
                          
                          </div>


                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              //flexWrap: "wrap",
                              justifyContent: "space-between",
                              //alignItems: "stretch",
                              //columnGap: "0rem",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "23px",
                                fontWeight: "300",
                                color: "#9C1006",
                                marginTop: -320,
                                width: "20%",
                              }}
                            >
                              {data.number}
                              {/* {key+1} */}
                            </p>

                            <p
                              style={{
                                fontSize: "16.5px",
                                fontWeight: "800",
                                color: "#000000",
                                marginTop: 0,
                                width: "80%",
                              }}
                            >
                              {data.name}
                            </p>

                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "700",
                                color: "#9C1006",
                                marginTop: 0,
                                width: "25%",
                              }}
                            >
                              {data.price}
                            </p>
                          </div>

                          <div style={{
                            width: "90%",
                            height: "0.5px",
                            backgroundColor: "gray",
                            marginBottom: "20px",
                            marginLeft: "6%",
                          }}>

                          </div>

                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "300",
                              fontStyle: "italic",
                              color: "#9C1006",
                              marginTop: -10,
                              maxWidth: 500,
                              marginLeft: 40,
                              textAlign: "left",
                            }}
                          >
                            {data.desc}
                          </p>

                          {/* <p style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#9C1006",
                        marginTop: 0,
                        width: "100%"
                      }}>
                        {data.number} -- {data.name} -- {data.price}
                      </p> */}
                        </div>
                      );
                    })}
                  </div>
                );
              })}

            </div>

          </div>
      </div>


      


    <div style={{
      margin: "20px",
      width: "80vw",
      maxWidth: "1000px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      
    }}>


    <div style={{
        width: "100%",
        //minWidth: "850px",
        height: "450px",
        backgroundColor: "white",
        borderRadius: "15px",
        marginBottom: 50,
        scrollbarWidth: "none",
        overflowY: "scroll"
        
      }}>

      {

      
      comments.toReversed()
      .filter(item => item.reviewed)
      .map((data, key) => {

        

        return <>
        <p style={{
          color: "#9C1006",
          marginBottom: 10,
          fontSize: 26,
          fontWeight: "200",
          textAlign: "left",
          marginLeft: "10%",
          maxWidth: 570,
          width: "80%"
        }}>
          {
            data.comment
          }
        </p>
  
        <div style={{
          backgroundColor: "gray",
          width: "80%",
          height: 2,
          marginLeft: "10%",
          marginBottom: -10
        }}>
  
        </div>
  
        <p style={{
          color: "black",
          fontSize: 20,
          fontWeight: "500",
          textAlign: "right",
          marginRight: "10%",
          marginBottom: -20
        }}>
          
          {data.name}
          
        </p>
        <p style={{
          color: "gray",
          fontSize: 16,
          fontWeight: "700",
          textAlign: "right",
          marginRight: "10%",
          marginTop: 20
        }}>
          {data.date.split("-")[2].split("T")[0]}/{data.date.split("-")[1]}/{data.date.split("-")[0]}
          
          
        </p>
        </>

      })

      }
      




    </div>




    {
      leaveComment 
      ?
      <div>
      <div style={{
        display: "flex",
        height: "50px"
      }}>
      <p style={{
        color: "#9C1006",
        fontSize: "19px",
        fontWeight: "600",
        //margin: "20px",

        marginLeft: 35,
        marginTop: 13
      }}>
        name
      </p>

      <input
        type="text"
        value= {cmntrName}
        placeholder= "Type your name here ..."
        style={{
          borderRadius: "10px",
          borderColor: "white",
          borderWidth: "20px",
          borderWidth: "5px",
          borderStyle: "solid",
          margin: 5,
          marginLeft: 10,
          fontSize: 18,
          width: "200px"
        }}

        onChange={(e) => {
          setCmntrName(e.target.value)
        }}
        >
      </input>
      </div>

      <div style={{
        display: "flex",
        height: "15vh"
      }}>
      <p style={{
        color: "#9C1006",
        fontSize: "19px",
        fontWeight: "600",
        //margin: "20px",
        marginTop: 8
      }}>
        comment
      </p>

      <textarea
        type="text"
        value= {cmntrDesc}
        placeholder= "Write what you think about jigarchi ..."
        style={{
          minWidth: "100px",
          width: "60vw",
          maxWidth: "700px",
          padding: "6px",
          borderRadius: "10px",
          borderColor: "white",
          borderWidth: "20px",
          borderWidth: "5px",
          borderStyle: "solid",
          margin: 5,
          marginLeft: 10,
          fontSize: 15
        }}

        onChange={(e) => {
          setCmntrDesc(e.target.value)
        }}
        >
      </textarea>
      </div>

      <div
              style={{
                width: 100,
                //maxWidth: 200,
                height: "40px",
                /* marginLeft: windowSize.innerWidth > 1400
                ? "14vw"
                : "10vw", */
                marginTop: "-8vh",
                marginBottom: 80,
                marginLeft: -20,
                backgroundColor: "#9C1006",
                border: "3.5px solid #9C1006",
                borderRadius: 10,
                cursor: "pointer",
              }}

              //onClick={executeScroll}
              onClick={() => {
                
                addComment();

              }}
            >
              <p
                style={{
                  marginTop: 6,
                  marginLeft: 2,
                  fontSize: "23px",
                  fontWeight: "300",

                  //color: selectedCategory == key ? "white" : "#9C1006"
                }}
              >
                Submit
              </p>
            
      </div>
      </div>
      :
      <div
              style={{
                width: 200,
                //maxWidth: 200,
                height: "40px",
                /* marginLeft: windowSize.innerWidth > 1400
                ? "14vw"
                : "10vw", */
                marginTop: -30,
                marginBottom: 30,
                //marginLeft: "35vw",
                backgroundColor: "#9C1006",
                border: "3.5px solid #9C1006",
                borderRadius: 10,
                cursor: "pointer",
              }}

              //onClick={executeScroll}
              onClick={() => {
                
                setLeaveComment(true);

              }}
            >
              <p
                style={{
                  marginTop: 6,
                  marginLeft: 2,
                  fontSize: "23px",
                  fontWeight: "300",

                  //color: selectedCategory == key ? "white" : "#9C1006"
                }}
              >
                Leave a comment
              </p>
            
      </div>

    }


      


    </div>




    <div>
      {/* <Welcome name="Edite" /> */}
    </div>

    {/* <div style={{
      height: 500,
      width: "90vw",
      //backgroundColor: "red"
    }}>
      {
        /* window.aclib.runBanner({
            zoneId: '8228822',
        }) *
      }
    </div> */}

      <div style={{
        backgroundColor: "white",
        borderRadius: 40,
      }}>
      <div
        style={{
          height: "240vw",
          width: "90vw",
          maxWidth: 1200,
          display: "flex",
          flexDirection: windowSize.innerWidth > 900
                  ? "row"
                  : "column",
          maxHeight: windowSize.innerWidth > 900
                  ? 500
                  : 1000,
          
          
          padding: 15
        }}
      >

        <div
          style={{
            width: windowSize.innerWidth > 900
              ? "50%"
              : "100%",
            height: "100%",
            maxHeight: 500,
            marginRight: 30,
            marginBottom: 30,
            transform: "translateY(0px)",
            position: "relative",
            borderRadius: 30,
            overflow: "hidden",
          }}
        >
          <Iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12075.532762722649!2d55.22519788431875!3d25.182863450366625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f4309ddd21e89%3A0x91010fc0e6acd4bb!2sJIGARCHI!5e0!3m2!1sen!2s!4v1689454248431!5m2!1sen!2s"
            width="100%"
            height="100%"
            allowfullscreen=""
            frameBorder="0"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></Iframe>
        </div>

        

      <div style={{
          display: "flex",
          flexDirection: "column",
          width: windowSize.innerWidth > 900
                  ? "50%"
                  : "100%",
            height: "100%",
        }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            //maxHeight: 200,
            //maxHeight: "40vw",
            backgroundColor: "gray",
            backgroundImage: `url(${bakke})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            borderRadius: 30,
            //display: "flex",
            //alignItems: "center"
          }}
        >
          
          {/* <Iframe
            src='//ad.a-ads.com/2314735?size=728x90'
            data-aa='2314735'
            //style=' border:0px; padding:0; overflow:hidden; ;'
            
            width="100%"
            height="100%"
            allowfullscreen=""
            frameBorder="0"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></Iframe> */}
        </div>

        
        
        
        {/* <div dangerouslySetInnerHTML={adMark()}></div> */}

        </div>

        
      </div>

      

      </div>


      
          
      

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <img
            src={modalPic}
            alt="Jigarchi"
            onClick={closeModal}
            style={{
              //maxWidth: "500px",
              width: "50vh",
            }}
          />
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          {/* <button onClick={closeModal}>close</button> */}
          {/* <div>I am a modal</div> */}
          {/* <form>
            <input />
            <button>tab navigation</button>
            <button>stays</button>
            <button>inside</button>
            <button>the modal</button>
          </form> */}
        </Modal>

      <Footer ws={windowSize} />


      
      
    </header>

    {/* <Slide
                  
    >
      
        <div className="each-slide-effect"
        >
          <img
            src={bakke}
            alt="Jigarchi"
            style={{
              height: "500px",
              width: "100%",
              //backgroundColor: "rgba(153,216,224, 0.6)",
            }}
          ></img>

        </div>

        <div className="each-slide-effect"
        >
          <img
            src={bakke}
            alt="Jigarchi"
            style={{
              height: "500px",
              width: "100%",
              //backgroundColor: "rgba(153,216,224, 0.6)",
            }}
          ></img>

        </div>

        
        
      
    </Slide> */}
    </>

    
  );
}

export default LandingPage;
